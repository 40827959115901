import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import img1 from '../images/about.jpg'
import PageBanner from '../common/PageBanner'
import axios from 'axios'

export default function About() {

  return (
    <React.Fragment>
      <PageBanner title="About us" />
      <div className='about_home page_s'>
        <Container>
          <Row className='align-items-center'>
            <Col md={12}>
              <div className='page_content'>
                <img src={img1} alt='' />
                <p>Welcome to [Hotel Name], where luxury meets comfort and hospitality knows no bounds.</p>
                <p>Nestled in the heart of [City Name], our hotel offers a serene retreat from the bustling city life while placing you mere moments away from its vibrant energy. Whether you're traveling for business or pleasure, our dedicated staff is committed to ensuring your stay is nothing short of exceptional.</p>
                <p>Indulge in our elegantly appointed rooms and suites, each meticulously designed to provide the utmost in comfort and style. With modern amenities and plush furnishings, you'll find the perfect sanctuary to unwind after a day of exploration.</p>
                <p>Savor culinary delights at our renowned restaurant, where our expert chefs craft exquisite dishes using the freshest ingredients. From international cuisine to local specialties, every bite is a journey for the senses.</p>
                <p>For those seeking relaxation, our spa offers a haven of tranquility where you can rejuvenate your mind, body, and soul. Treat yourself to a massage, facial, or holistic treatment, and let our skilled therapists melt away your stress.</p>
                <p>Hosting an event? Look no further than our versatile meeting and event spaces, equipped with state-of-the-art technology and supported by our experienced event planning team. Whether it's a corporate conference, wedding, or social gathering, we'll ensure every detail is taken care of with precision and care.</p>
                <p>At [Hotel Name], we believe in going above and beyond to exceed your expectations. From personalized service to thoughtful touches, we strive to create unforgettable memories that will last a lifetime. Experience the pinnacle of hospitality with us and discover why we're the preferred choice for discerning travelers.</p>
                <p>Book your stay with us today and embark on a journey of luxury, comfort, and unparalleled hospitality. We look forward to welcoming you to [Hotel Name].</p>
              </div>

            </Col>
            
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}