import React from 'react'
import { Carousel } from 'react-bootstrap'
import banner from '../images/banenr.jpg'
import banner2 from '../images/banenr2.jpg'
export default function Banner() {
   
    // 
  return (
      <React.Fragment>
          <div className='banner_style'>
          <Carousel>
              <Carousel.Item>
                  <img src={banner} alt='' />
              </Carousel.Item>
              <Carousel.Item>
                  <img src={banner2} alt='' />
              </Carousel.Item>
              <Carousel.Item>
                  <img src={banner} alt='' />
              </Carousel.Item>
              </Carousel>
          </div>
    </React.Fragment>
  )
}